
//? set fontSize
const setFontSize = (device, range) => {
  // mobile range = 414;
  // desktop range = 1200;
  let width = document.documentElement.clientWidth;
  switch (device) {
    case "mobile":
      //判斷視窗寬度 調整font-size
      if (width > range) {
        let scale = width / (range / 16);
        $("html").css("font-size", scale + "px");
      } else {
        $("html").css("font-size", "16px");
      }
      break;
    case "desktop":
      //判斷視窗寬度 調整font-size
      if (width >= 1920) {
        $("html").css("font-size", "16px");
      } else if (width > range) {
        let scale = width / (range / 12);
        $("html").css("font-size", scale + "px");
      } else {
        $("html").css("font-size", "12px");
      }
      break;
  }
};

// 主 nav 開關
const navCollapse = () => {
  const btnMenu = $(".header-layout .hamburger");
  const navMenu = $(".header-layout .navbar");
  const closeMenu = () => {
    btnMenu.removeClass("hamburger--active");

    navMenu.removeClass("navbar--active");
  };
  const openMenu = () => {
    btnMenu.addClass("hamburger--active");
    navMenu.addClass("navbar--active");
  };
  btnMenu.unbind("click").on("click", function () {
    $(this).hasClass("hamburger--active") ? closeMenu() : openMenu();
  });

  // 點擊 nav以外的 element 關閉 nav
  $(".main-content").unbind("click").on("click", closeMenu);
};
// goTop 按鈕動作
const goTopOnScroll = () => {
  $('[data-id="go-top"]').hide();

  $('[data-id="go-top"] .btn').on("click touchstart", function (e) {
    e.preventDefault();
    // $(".main-layout").stop().animate({ scrollTop: 0 }, 500);
    $("html, body").stop().animate({ scrollTop: 0 }, "slow");
    // $('body').animate({ scrollTop: 0 }, 500);
  });

  $(window).scroll(function () {
    if ($(this).scrollTop() > 100) {
      // $('[data-id="go-top"]').fadeIn();
      $('[data-id="go-top"]').stop().fadeIn("1000");
    } else {
      $('[data-id="go-top"]').stop().fadeOut();
    }
  });
  // 點擊右下 goTop 按鈕返回到最上
  // $('[data-id="go-top"]')
  // .unbind('click')
  //   .on('click', function () {
  //     // $(window).scrollTop(0);
  //     $('body').animate({scrollTop: 0},500)
  //   // $('body').animate({ scrollTop: 0 }, 500);
  // });
};

const activeOwlPagination = ({ index }) => {
  $('.slide-nav li.now').removeClass("now");
  const len = $('.slide-nav li').length;
  const pid = index%len;
  $('.slide-nav li').eq(pid).addClass("now");
}

const trySetupSlider = () => {
  if (!document.body.querySelector('.whos_list')) return;
  // TODO: reset slider
  $('.owl-carousel').owlCarousel('destroy');
  const autoplayTimeout = 7000;
  const $slider = $('.owl-carousel');
  const option = {
    mouseDrag: true,
    items: 1,
    loop: true,
    autoplay: true,
    autoplayTimeout,
    onDragged: event => activeOwlPagination(event.page),
    onChanged: event => activeOwlPagination(event.page)
  }
  $slider.owlCarousel(option);
  $slider.on('changed.owl.carousel', function() {
    // 重新启动自动播放
    $slider.trigger('stop.owl.autoplay'); // 先停止自动播放
    $slider.trigger('play.owl.autoplay', [autoplayTimeout]); // 5000 毫秒（5秒）为自动播放间隔
  });

  $('.slide-nav li').click(function () {
    const index = $(this).index();
    activeOwlPagination({ index });
    $slider.trigger('to.owl.carousel', index);
  })
}

const showTagContent = (tagName = 'introduction') => {
  if (!document.body.querySelector('[data-content=anti-scam-solution]')) return;
  $('[data-content=anti-scam-solution] .sub-container').hide()
  $(`[data-content=anti-scam-solution] .sub-container--${tagName}`).fadeIn();
}


// solution 頁面標籤切換
const setPageTagOfSolution = () => {
  if (!document.body.querySelector('[data-content=anti-scam-solution]')) return;
  $(".page-tag li")
    .unbind("click")
    .on("click", function () {
      let nowThis = $(this);
      nowThis
        .addClass("now")
        .siblings()
        .removeClass("now");

      let tagName = nowThis.data("content");
      showTagContent(tagName);
    });
}
const navbar = () => {
  const navReset = () => {
    $(".main-layout .main-content").children().hide();
  };
  //? 預設高度與頁面防呆
  navReset();
  $(".main-layout .main-content .homepage").show();

  //? menu選單
  $(".main-layout .header-layout .navbar .nav__item")
    .unbind("click")
    .on("click", function () {
      let nowThis = $(this);
      nowThis
        .addClass("nav__item--active")
        .siblings()
        .removeClass("nav__item--active");

      // 切換對應內容
      let tab = nowThis.data("tab");

      navReset();
      $(".main-layout").animate({ scrollTop: 0 }, 1000);
      $(".main-layout .main-content")
        .children("[data-content = " + tab + "]")
        .show();

      $(".header-layout .hamburger").removeClass("hamburger--active");
      $(".header-layout .navbar").removeClass("navbar--active");
      // for solution page tag
      setPageTagOfSolution();
      showTagContent();

      // 嘗試執行 slider  
      trySetupSlider();
    });

  //? home-logo鍵回首頁
  $(".main-layout .header-layout .main-logo")
    .unbind("click")
    .on("click", function () {
      let nowThis = $(this);

      // // 切換對應內容
      let tab = nowThis.data("tab");

      navReset();
      $(".main-layout").animate({ scrollTop: 0 }, 100);
      // $(".main-layout .main-content .homepage").show();
      $(".main-layout .main-content")
        .children("[data-content = " + tab + "]")
        .show();
      $('[data-tab="home"]')
        .addClass("nav__item--active")
        .siblings()
        .removeClass("nav__item--active");
      $(".header-layout .hamburger").removeClass("hamburger--active");
      $(".header-layout .navbar").removeClass("navbar--active");
      // console.log(tab);
    });
  //? 單一 btn Contact us 連結
  $('[data-tab="contact-us"]')
    .unbind("click")
    .on("click", function () {
      let nowThis = $(this);

      // // 切換對應內容
      let tab = nowThis.data("tab");

      navReset();
      $(".main-layout").animate({ scrollTop: 0 }, 1000);
      // $(".main-layout .main-content .homepage").show();
      $(".main-layout .main-content")
        .children("[data-content = " + tab + "]")
        .show();
      $('[data-tab="contact-us"]')
        .addClass("nav__item--active")
        .siblings()
        .removeClass("nav__item--active");
      $(".header-layout .hamburger").removeClass("hamburger--active");
      $(".header-layout .navbar").removeClass("navbar--active");
      // console.log(tab);
    });
};

//? src https://dev.to/nirazanbasnet/dont-use-100vh-for-mobile-responsive-3o97
//? resize each device height
const documentHeight = () => {
  const doc = document.documentElement;
  doc.style.setProperty("--doc-height", `${window.innerHeight}px`);
};

$(document).ready(function () {
  // console.log("Hello Main.js Here!");
  //? set html base font size
  documentHeight();
  setFontSize();
  window.onresize = function () {
    setFontSize("desktop", "1200");
    documentHeight();
  };
  navCollapse();
  goTopOnScroll();
  navbar();

  // for dev
  // setTimeout(() => {
  //   $('.main-layout .header-layout .navbar .nav__item:nth-child(5)').click();
  // }, 100)
  // setTimeout(() => {
  //   $('.page-tag li:nth-child(2)').click();
  // }, 200)
});